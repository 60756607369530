<script setup lang="ts">
import { onMounted, useSlots, ref, Ref } from 'vue';

const slots = useSlots();
const props = defineProps<DataEditCardProps>();
const fieldComponents: Ref<Array<any>> = ref([]);

onMounted(async () => {
    props.dataObject.on('DataLoaded', () => {
        if (props.dataObject.current.PrimKey && slots.default) {
            fieldComponents.value = slots.default().map(slot => {
                let fieldMatch = props.dataObject['fields']['fields'].find((f: any) => f.name == slot.props.field);
                slot.props['type'] = fieldMatch ? fieldMatch.type : 'text';
                slot.props['row'] = props.dataObject.current;
                return slot;
            }).filter(slot => {
                return props.dataObject.fields.fields.some((f: any) => f.name == slot.props.field)
            });
        }
    });

    await props.dataObject.load();
});
</script>

<script lang="ts">
export interface DataEditCardProps {
    dataObject: any;
    title: string;
}
</script>

<template v-if="dataObject.current && dataObject.current.PrimKey">
    <div class="card p-3" v-if="dataObject.current.PrimKey">
        <div class="row">
            <div class="col-12 d-flex justify-content-between">
                <div class="fs-4" v-if="title">{{ title }}</div>
                <div class="button-group justify-content-end d-flex gap-1">
                    <button class="btn btn-sm btn-primary" type="button" :disabled="!dataObject.storage.hasChanges" @click="dataObject.save()">
                        <span v-if="!dataObject.current.isSaving">Save</span>
                        <div v-else class="spinner-border spinner-border-sm" role="status">
                            <span class="visually-hidden">Loading...</span>
                        </div>
                    </button>
                    <button class="btn btn-sm btn-secondary" :disabled="!dataObject.storage.hasChanges" @click="dataObject.cancelChanges()">Cancel</button>
                </div>
            </div>
        </div>
        <div class="row">
            <template v-for="slot in fieldComponents">
                <div class="col-12 col-md-6 mt-2 col-xl-4">
                    <component :is="slot"></component>
                </div>
            </template>
        </div>
    </div>
</template>